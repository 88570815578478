<template>
  <v-form>
    <v-container fluid>
      <v-card max-width="800px" class="mt-4 mx-auto">
        <v-card-title v-text="$t('TenantAccountSettings')" />
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('invoiceNumberRange')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="invoiceNumberRangeFormatErrors"
                  :label="$t('format')"
                  @blur="$v.item.invoiceNumberRange.format.$touch()"
                  @input="$v.item.invoiceNumberRange.format.$touch()"
                  required
                  v-model="item.invoiceNumberRange.format"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="invoiceNumberRangeStartNumberErrors"
                  :label="$t('startNumber')"
                  @blur="$v.item.invoiceNumberRange.startNumber.$touch()"
                  @input="$v.item.invoiceNumberRange.startNumber.$touch()"
                  required
                  v-model="item.invoiceNumberRange.startNumber"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :error-messages="invoiceNumberRangeNumberCycleErrors"
                  :label="$t('numberCycle')"
                  @blur="$v.item.invoiceNumberRange.numberCycle.$touch()"
                  @input="$v.item.invoiceNumberRange.numberCycle.$touch()"
                  required
                  :items="numberCycleValues"
                  v-model.number="item.invoiceNumberRange.numberCycle"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('creditNumberRange')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="creditNumberRangeFormatErrors"
                  :label="$t('format')"
                  @blur="$v.item.creditNumberRange.format.$touch()"
                  @input="$v.item.creditNumberRange.format.$touch()"
                  required
                  v-model="item.creditNumberRange.format"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="creditNumberRangeStartNumberErrors"
                  :label="$t('startNumber')"
                  @blur="$v.item.creditNumberRange.startNumber.$touch()"
                  @input="$v.item.creditNumberRange.startNumber.$touch()"
                  required
                  v-model="item.creditNumberRange.startNumber"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :error-messages="creditNumberRangeNumberCycleErrors"
                  :label="$t('numberCycle')"
                  @blur="$v.item.creditNumberRange.numberCycle.$touch()"
                  @input="$v.item.creditNumberRange.numberCycle.$touch()"
                  required
                  :items="numberCycleValues"
                  v-model.number="item.creditNumberRange.numberCycle"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('offerNumberRange')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="offerNumberRangeFormatErrors"
                  :label="$t('format')"
                  @blur="$v.item.offerNumberRange.format.$touch()"
                  @input="$v.item.offerNumberRange.format.$touch()"
                  required
                  v-model="item.offerNumberRange.format"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="offerNumberRangeStartNumberErrors"
                  :label="$t('startNumber')"
                  @blur="$v.item.offerNumberRange.startNumber.$touch()"
                  @input="$v.item.offerNumberRange.startNumber.$touch()"
                  required
                  v-model="item.offerNumberRange.startNumber"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :error-messages="offerNumberRangeNumberCycleErrors"
                  :label="$t('numberCycle')"
                  @blur="$v.item.offerNumberRange.numberCycle.$touch()"
                  @input="$v.item.offerNumberRange.numberCycle.$touch()"
                  required
                  :items="numberCycleValues"
                  v-model.number="item.offerNumberRange.numberCycle"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('orderConfirmationNumberRange')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="orderConfirmationNumberRangeFormatErrors"
                  :label="$t('format')"
                  @blur="$v.item.orderConfirmationNumberRange.format.$touch()"
                  @input="$v.item.orderConfirmationNumberRange.format.$touch()"
                  required
                  v-model="item.orderConfirmationNumberRange.format"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="orderConfirmationNumberRangeStartNumberErrors"
                  :label="$t('startNumber')"
                  @blur="
                  $v.item.orderConfirmationNumberRange.startNumber.$touch()
                "
                  @input="
                  $v.item.orderConfirmationNumberRange.startNumber.$touch()
                "
                  required
                  v-model="item.orderConfirmationNumberRange.startNumber"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :error-messages="orderConfirmationNumberRangeNumberCycleErrors"
                  :label="$t('numberCycle')"
                  @blur="
                  $v.item.orderConfirmationNumberRange.numberCycle.$touch()
                "
                  @input="
                  $v.item.orderConfirmationNumberRange.numberCycle.$touch()
                "
                  required
                  :items="numberCycleValues"
                  v-model.number="item.orderConfirmationNumberRange.numberCycle"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('customerNumberRange')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="customerNumberRangeFormatErrors"
                  :label="$t('format')"
                  @blur="$v.item.customerNumberRange.format.$touch()"
                  @input="$v.item.customerNumberRange.format.$touch()"
                  required
                  v-model="item.customerNumberRange.format"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                  :error-messages="customerNumberRangeStartNumberErrors"
                  :label="$t('startNumber')"
                  @blur="$v.item.customerNumberRange.startNumber.$touch()"
                  @input="$v.item.customerNumberRange.startNumber.$touch()"
                  required
                  v-model="item.customerNumberRange.startNumber"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :error-messages="customerNumberRangeNumberCycleErrors"
                  :label="$t('numberCycle')"
                  @blur="$v.item.customerNumberRange.numberCycle.$touch()"
                  @input="$v.item.customerNumberRange.numberCycle.$touch()"
                  required
                  :items="numberCycleValues"
                  v-model.number="item.customerNumberRange.numberCycle"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h3 v-text="$t('paymentMethods')"></h3>
              <PaymentMethodCard :values="item.paymentMethods" />
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  dark
                  @click="paymentMethodDialogControl()"
              >{{ $t("AddPaymentMethod") }}
              </v-btn
              >
              <PaymentMethodDialog
                  v-model="showPaymentMethodDialogForm"
                  :paymentMethodItem="{}"
                  :handle-created="onPaymentMethodCreated"
                  :show-handle="showPaymentMethodDialogForm"
                  :title="$t('AddPaymentMethod')"
                  @close="paymentMethodDialogControl(true)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h3 v-text="$t('dunningLevels')"></h3>
              <DunningLevelCard :values="item.dunningLevels" />
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  dark
                  @click="dunningLevelDialogControl()"
              >{{ $t("AddDunningLevel") }}
              </v-btn
              >
              <DunningLevelDialog
                  v-model="showDunningLevelDialogForm"
                  :dunningLevelItem="{}"
                  :handle-created="onDunningLevelCreated"
                  :show-handle="showDunningLevelDialogForm"
                  :title="$t('AddDunningLevel')"
                  @close="dunningLevelDialogControl(true)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h3 v-text="$t('bankAccounts')"></h3>
              <BankAccountCard :values="item.bankAccounts" />
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  dark
                  @click="bankAccountDialogControl()"
              >{{ $t("AddBankAccount") }}
              </v-btn
              >
              <BankAccountDialog
                  v-model="showBankAccountDialogForm"
                  :bankAccountItem="{}"
                  :handle-created="onBankAccountCreated"
                  :show-handle="showBankAccountDialogForm"
                  :title="$t('AddBankAccount')"
                  @close="bankAccountDialogControl(true)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h3 v-text="$t('DefaultTextTemplates')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultOfferHeaderText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.defaultOfferHeaderText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultOfferFooterText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.defaultOfferFooterText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultInvoiceHeaderText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.defaultInvoiceHeaderText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultInvoiceFooterText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.defaultInvoiceFooterText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultDunningHeaderText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.defaultDunningHeaderText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultDunningFooterText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.defaultDunningFooterText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('invoiceMailText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.invoiceMailText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('creditMailText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.creditMailText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('cancellationMailText')"
                  :items="textTemplates"
                  item-value="@id"
                  v-model="item.cancellationMailText"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h3 v-text="$t('CustomerSettings')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                  :label="$t('defaultCustomerGroup')"
                  :items="frontendGroups"
                  item-value="@id"
                  v-model="item.defaultCustomerGroup"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="`${data.item.name}`">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                  :label="$t('customerLoginEnabled')"
                  @blur="$v.item.enabled.$touch()"
                  @input="$v.item.enabled.$touch()"
                  v-model="item.customerLoginEnabled"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import has from "lodash/has";
import PaymentMethodCard from "../../components/paymentMethod/PaymentMethodCard";
import PaymentMethodDialog from "../../components/paymentMethod/PaymentMethodDialog";
import BankAccountCard from "../../components/bankAccount/BankAccountCard";
import DunningLevelCard from "../../components/dunningLevel/DunningLevelCard";
import DunningLevelDialog from "../../components/dunningLevel/DunningLevelDialog";
import BankAccountDialog from "../../components/bankAccount/BankAccountDialog";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

const numberRangeValidation = {
  format: {
    required,
  },
  startNumber: {
    required,
  },
  numberCycle: {
    required,
  },
};

export default {
  name: "TenantAccountSettingForm",
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },
    refForm: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },
    initialValues: {
      type: Object,
      default: () => {
      },
    },
    handleSubmit: {
      type: Function,
      required: false,
    },
    handleReset: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      invoiceNumberRange: {},
      creditNumberRange: {},
      offerNumberRange: {},
      orderConfirmationNumberRange: {},
      customerNumberRange: {},
      paymentMethods: [],
      dunningLevels: [],
      numberCycleValues: [
        { text: this.$t("Annual"), value: 0 },
        { text: this.$t("Monthly"), value: 10 },
        { text: this.$t("Never"), value: 30 },
        //{ text: this.$t("Weekly"), value: 20 },
      ],
      showPaymentMethodDialogForm: [],
      showDunningLevelDialogForm: [],
      showBankAccountDialogForm: [],
    };
  },
  components: {
    PaymentMethodCard,
    PaymentMethodDialog,
    DunningLevelCard,
    DunningLevelDialog,
    BankAccountCard,
    BankAccountDialog,
  },
  mounted() {
    this.textTemplatesGetSelectedItems();
    this.frontendGroupsGetSelectedItems();
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    ...mapFields("textTemplate", {
      textTemplates: "selectItems",
    }),
    ...mapFields("frontendGroup", {
      frontendGroups: "selectItems",
    }),
    invoiceNumberRangeFormatErrors() {
      return this.numberRangeErrors("invoiceNumberRange", "format");
    },
    invoiceNumberRangeStartNumberErrors() {
      return this.numberRangeErrors("invoiceNumberRange", "startNumber");
    },
    invoiceNumberRangeNumberCycleErrors() {
      return this.numberRangeErrors("invoiceNumberRange", "numberCycle");
    },
    creditNumberRangeFormatErrors() {
      return this.numberRangeErrors("creditNumberRange", "format");
    },
    creditNumberRangeStartNumberErrors() {
      return this.numberRangeErrors("creditNumberRange", "startNumber");
    },
    creditNumberRangeNumberCycleErrors() {
      return this.numberRangeErrors("creditNumberRange", "numberCycle");
    },
    offerNumberRangeFormatErrors() {
      return this.numberRangeErrors("offerNumberRange", "format");
    },
    offerNumberRangeStartNumberErrors() {
      return this.numberRangeErrors("offerNumberRange", "startNumber");
    },
    offerNumberRangeNumberCycleErrors() {
      return this.numberRangeErrors("offerNumberRange", "numberCycle");
    },
    orderConfirmationNumberRangeFormatErrors() {
      return this.numberRangeErrors("orderConfirmationNumberRange", "format");
    },
    orderConfirmationNumberRangeStartNumberErrors() {
      return this.numberRangeErrors(
          "orderConfirmationNumberRange",
          "startNumber"
      );
    },
    orderConfirmationNumberRangeNumberCycleErrors() {
      return this.numberRangeErrors(
          "orderConfirmationNumberRange",
          "numberCycle"
      );
    },
    customerNumberRangeFormatErrors() {
      return this.numberRangeErrors("customerNumberRange", "format");
    },
    customerNumberRangeStartNumberErrors() {
      return this.numberRangeErrors("customerNumberRange", "startNumber");
    },
    customerNumberRangeNumberCycleErrors() {
      return this.numberRangeErrors("customerNumberRange", "numberCycle");
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
    ...mapActions("tenantAccountSetting", {
      editItem: "edit",
      reset: "resetData",
      retrieve: "load",
      updateTenantAccountSetting: "update",
    }),
    ...mapActions("paymentMethod", {
      deletePaymentMethodItem: "del",
      editPaymentMethodItem: "edit",
      resetPaymentMethod: "resetData",
      retrievePaymentMethod: "load",
    }),
    ...mapActions({
      textTemplatesGetSelectedItems: "textTemplate/fetchSelectItems",
    }),
    ...mapActions({
      frontendGroupsGetSelectedItems: "frontendGroup/fetchSelectItems",
    }),
    numberRangeErrors(context, field) {
      const errors = [];
      if (!this.$v.item[context][field].$dirty) {
        return errors;
      }
      has(this.violations, field) && errors.push(this.violations[field]);
      !this.$v.item[context][field].required &&
      errors.push(this.$t("Field is required"));
      return errors;
    },
    onPaymentMethodCreated(createdPaymentMethod) {
      if (this.refForm !== "createForm") {
        this.item.paymentMethods.push(createdPaymentMethod);
        let fakeItem = {
          "@id": this.item["@id"],
          paymentMethods: this.item.paymentMethods,
        };
        this.updateTenantAccountSetting(fakeItem);
      } else {
        this.item.paymentMethods.push(
            createdPaymentMethod["@id"]
                ? createdPaymentMethod
                : {
                  ...createdPaymentMethod,
                  ["@id"]: `paymentMethod-${ this.item.paymentMethods.length }`,
                  notPersisted: true,
                }
        );
      }
    },
    onDunningLevelCreated(createdDunningLevel) {
      if (this.refForm !== "createForm") {
        this.item.dunningLevels.push(createdDunningLevel);
        let fakeItem = {
          "@id": this.item["@id"],
          dunningLevels: this.item.dunningLevels,
        };
        this.updateTenantAccountSetting(fakeItem);
      } else {
        this.item.dunningLevels.push(
            createdDunningLevel["@id"]
                ? createdDunningLevel
                : {
                  ...createdDunningLevel,
                  ["@id"]: `dunningLevel-${ this.item.dunningLevels.length }`,
                  notPersisted: true,
                }
        );
      }
    },
    onBankAccountCreated(createdBankAccount) {
      if (this.refForm !== "createForm") {
        this.item.bankAccounts.push(createdBankAccount);
        let fakeItem = {
          "@id": this.item["@id"],
          bankAccounts: this.item.bankAccounts,
        };
        this.updateTenantAccountSetting(fakeItem);
      } else {
        this.item.bankAccounts.push(
          createdBankAccount["@id"]
                ? createdBankAccount
                : {
                  ...createdBankAccount,
                  ["@id"]: `bankAccount-${ this.item.createdBankAccount.length }`,
                  notPersisted: true,
                }
        );
      }
    },
    paymentMethodDialogControl(close = false) {
      if (!close) {
        this.showPaymentMethodDialogForm.push("new");
      } else {
        this.showPaymentMethodDialogForm.splice(
            this.showPaymentMethodDialogForm.indexOf("new"),
            1
        );
      }
    },
    dunningLevelDialogControl(close = false) {
      if (!close) {
        this.showDunningLevelDialogForm.push("new");
      } else {
        this.showDunningLevelDialogForm.splice(
            this.showDunningLevelDialogForm.indexOf("new"),
            1
        );
      }
    },
    bankAccountDialogControl(close = false) {
      if (!close) {
        this.showBankAccountDialogForm.push("new");
      } else {
        this.showBankAccountDialogForm.splice(
            this.showBankAccountDialogForm.indexOf("new"),
            1
        );
      }
    },
  },
  validations: {
    item: {
      invoiceNumberRange: {
        ...numberRangeValidation,
      },
      creditNumberRange: {
        ...numberRangeValidation,
      },
      offerNumberRange: {
        ...numberRangeValidation,
      },
      orderConfirmationNumberRange: {
        ...numberRangeValidation,
      },
      customerNumberRange: {
        ...numberRangeValidation,
      },
    },
  },
};
</script>
